<template>
  <div class="mx-3">
    <h2 class="px-5 py-5">{{ $t('Materials Movment Report') }}</h2>
    <v-row class="px-10 py-1">
      <v-col md="3">
        <AutoCompleteField :label="$t('Material Name')" endPoint="/inventories/materials/auto-complete"
          v-model="filters.material_name" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('folder name')" endPoint="/inventories/folders/auto-complete"
          v-model="filters.folder" />
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date" v-model="filters.from">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date" v-model="filters.to">
        </v-text-field>
      </v-col>
      <v-col md="3">
        <v-autocomplete :label="$t('Client Account')" :items="accountItems" :loading="accountLoading" color="#757575"
          item-text="name" item-value="id" v-model="filters.client_account" @keypress="fetchAutoCompleteAccount($event)">
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Client Account')" endPoint="/accounting/account/auto-complete"
          v-model="filters.client_account" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('cost center')" endPoint="/accounting/cost-center/auto-complete"
          v-model="filters.cost_center" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('term')" endPoint="/terms" v-model="filters.term" />
      </v-col>
      <v-col md="3">
        <v-select :dark="$store.state.isDarkMode" :items="itemsInvoece" :label="$t('Invoice Name')" multiple
          item-text="name" item-value="id" v-model="filters.invoice_name"></v-select>
      </v-col>
      <v-col md="3">
        <v-select :dark="$store.state.isDarkMode" v-model="filters.material_type" :items="itemMaterialType"
          :label="$t('Material Type')" item-text="name" item-value="id"></v-select>
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Beneficiary')" endPoint="/accounting/account/auto-complete"
          v-model="filters.beneficiary" />
      </v-col>
      <v-col md="3">
        <AutoCompleteField :label="$t('Inventory')" endPoint="/inventories/auto-complete"
          v-model="filters.inventory_name" />
      </v-col>
      <v-col md="3">
        <v-select v-model="filters.source" :items="sourceType" :label="$t('Source')" item-text="name"
          item-value="value"></v-select>
      </v-col>
      <v-col md="6">
        <v-text-field v-model="filters.desc" :label="$t('description')" />
      </v-col>
      <v-col md="3"></v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
      </v-col>
      <v-col md="3" class="mb-3 py-0">
        <ExportExcel end-point="/inventories/materials/movement/export" :params="filters" />
      </v-col>
    </v-row>

    <v-data-table :dark="$store.state.isDarkMode" :headers="headers" :loading="loading" :items="tableItems"
      class="elevation-1 px-10" hide-default-footer :items-per-page="10">
      <template v-slot:item.actions="{ item }">

        <v-btn @click="detailsHandler(item)" color="blue" icon>
          <v-icon class="ml-1">mdi-eye</v-icon></v-btn>
      </template>
    </v-data-table>
    <v-pagination v-if="!loading && tableItems.length" @input="getAll" v-model="page"
      :length="Math.ceil(total / 10)"></v-pagination>
  </div>
</template>
  
<script>
import axios from "axios";
import i18n from '@/i18n';
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";


export default {
  components: { AutoCompleteField, ExportExcel, },
  data: () => ({
    tableItems: [],
    loading: false,
    page: 1,
    total: 1,
    headers: [
      { text: i18n.t("id"), value: "invoice_id" },
      { text: i18n.t("date"), value: "date" },
      { text: i18n.t('Document'), value: "invoice" },
      { text: i18n.t('Client'), value: "client" },
      { text: i18n.t('Source'), value: "document" },
      { text: i18n.t('Material Name'), value: "material_name" },
      { text: i18n.t('Amount'), value: "amount" },
      { text: i18n.t('price'), value: "price" },
      { text: i18n.t('total'), value: "total" },
      { text: i18n.t('Subject Balance'), value: "material_quantity" },
      { text: i18n.t('description'), value: "desc" },
      { text: "", value: "actions" },
    ],
    itemsInvoece: [],
    itemMaterialType: [],
    sourceType: [
      { name: 'invoice', value: 'invoice' }, { name: 'inventory', value: 'delivery' },
    ],
    filters: {
      source: null,
      material_name: null,
      folder: null,
      from: null,
      to: null,
      invoice_name: [],
      material_type: null,
      client_account: null,
      desc: null,
      invoice_type: null,
      inventory_name: null,
      beneficiary: null,
      corresponding_account: null,
      cost_center: null,
      term: null,
    },
  }),
  methods: {
    clearFilter() {
      this.filters.client_account = undefined;
      this.filters.folder = undefined;
      this.filters.cost_center = undefined;
      this.filters.from = undefined;
      this.filters.to = undefined;
      this.filters.term = undefined;
      this.filters.material_name = undefined;
      this.filters.source = undefined;
      this.filters.corresponding_account = undefined;
      this.filters.desc = undefined;
      this.filters.inventory_name = undefined;
      this.filters.material_type = undefined;
      this.filters.invoice_name = undefined;
      this.filters.invoice_type = undefined;
      this.filters.beneficiary = undefined;
      this.page = 1;
      this.getAll();
    },
    async getAll(page) {
      try {
        if (!page) this.page = 1;
        const filterTemp = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) filterTemp[key] = this.filters[key];
        });
        this.loading = true;
        const res = await axios.get("inventories/materials/movement", {
          params: {
            size: 10,
            page: this.page,
            ...filterTemp,
          },
        });
        const arr = Array.from(res.data.data)
        if (Array.isArray(arr)) {
          this.tableItems = arr.map((el) => {
            return {...el,};
          });
        }
        this.total = res.data.total;
      } finally {
        this.loading = false;
      }
    },
    async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.itemsInvoece = res.data.data.map((el) => ({
        ...el,
      }));
    },
    async getallType() {
      const res = await axios.get("/inventories/material-types/value/index");
      this.itemMaterialType = res.data.data;
    },
    detailsHandler(item) {
      if (item.source == "Invoice") {
        this.$router.push(`/edit-invoice/${item.invoice_id}?invoice_id=${item.invoice_type_id}`);
      }
      else {
        this.$router.push(`/edit-request/${item.invoice_id}?invoice_id=${item.invoice_type_id}&wear_house=${2}`);
      }
    },
  },
  created() {
    this.getallType();
    this.getInvoiceTypes();
  },
};
</script>
  
<style></style>
  